.footer{
    background-color: black;
    color: white;
    padding: 5% 0%;
    margin-top: 8%;
}

.partners-block{
    text-align: center;
}

.partners-image-container{
    gap: 25px;
    flex-wrap: wrap;
}

.partner-logos{
    width: 100%;
    height: auto;
}

.partners-underline{
    width: 80%;
    height: 1px;
    margin: 20px auto;
    color: gray;
}

.trail-desc{
  justify-content: space-between;
  padding: 0% 10%;
}

.desc-para{
    width: 70%;
}

.footer-vid{
    width: 20%;
}

a{
    color: white;
}

.column{
    flex-direction: column;
    gap: 20px;
    text-align: left !important;
     align-items: start !important;
}

.footer-main-content{
    justify-content: space-between;
    align-items: start;
    padding: 20px;
    padding: 0% 10%;
}

@media (max-width: 767px){
    .footer{
        padding: 10px 5px 40px 5px;
    }
    .footer-main-content{
        flex-direction: column;
        align-items: center !important;
    }
    .column{
        align-items: center !important;
    }
    .footer-main-content{
        gap: 38px;
    }

    .trail-desc{
        text-align: center;
        flex-direction: column;
        gap: 20px;

    }

    .footer-vid{
        width: 200px;
    }
}