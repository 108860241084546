.categories-main-container{
    padding: 5% 10%;
}

.categories-upper{
    justify-content: space-between;
    margin-bottom: 10px;
}

.categories-main-container {
    position: relative;
  }
  
  .slider-wrapper {
    display: flex;
    gap: 20px;
    transition: transform 0.5s ease-in-out;
    width: 100%;
  }

  .slider-main-wrap{
    overflow: auto;
  }

  .slider-main-wrap::-webkit-scrollbar{
    display: none;
  }
  
  .slider-images {
    object-fit: cover;
    border-radius: 12px;
    width: 220px;
    aspect-ratio: auto 240 / 180;
    height: 180px;
  }
  
  .arrow {
    font-size: 28px;
    color: grey;
    cursor: pointer;
    user-select: none;
  }
  
 .arrow.disable{
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
 }

 @media (max-width: 767px){
  .slider-images{
    width: 200px;
  }
  .categories-main-container{
    padding: 8% 4% 0% 4%;
  }
  .arrow{
    display: none;
  }
}

@media (max-width: 840px){
.arrow{
  display: none;
}
}