.pic-advertisement{
    padding: 0% 10%;
    gap: 24px;
    
}

.vid-advertisement{
    gap: 24px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.categ-cont{
    margin: 7% 10%;
    position: relative;
    top: -130px;
    margin-bottom: 14%;
}
.first-advertisement, .second-advertisement{
    width: 100%;
    border-radius: 12px;
}

.first-advertisement-video, .second-advertisement-video{
    width: 564px;
    border-radius: 12px;
}


.plane-photo{
    width: 56%;
    aspect-ratio: 3/2;
    z-index: 2;
    position: absolute;
    top: 0;
    cursor: pointer;
}

.modal{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 6;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  
}

.visa-photo{
    width: 48%;
    position: absolute;
    top: -18px;
    left: 58%;
    cursor: pointer;
    aspect-ratio: 3/2;
}

.cross-btn{
    position: absolute;
    top: 2%;
    right: 2%;
    font-size: 25px;
    cursor: pointer;
    color: white;
    z-index: 6;
}

.form-heading{
    background-color: #08B367;
    border-radius: 12px;
}


@media (max-width: 1028px){
    .modal{
        overflow: auto;
    }
    .pic-advertisement, .vid-advertisement{
        flex-wrap: wrap;
    }
    .categ-cont{
        display: flex;
        flex-direction: column;
        top: 0;
        margin: 0px;
        align-items: center;
        padding-left: 10%;
    }
    .visa-photo, .plane-photo{
        width: 100%;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        
    }
   
    .plane-photo{
        width: 120% !important;
        margin-bottom: -30px;
     
    }

    
}

.form-main{
    background-color: white;
    margin: 8% 20% 14% 20%;
   /* padding: 20px; */
   border-radius: 12px;
  text-align: center;
background-color: #f6edee;
border: 1px solid black;
   }
   
   .f{
       position: relative;
   }
   
   .name{
       gap: 40px;
       margin-top: 20px;
       text-align: left;
       padding-left: 20px;
   }
   

   .in{
       font-size: 20px;
       /* min-width: 400px; */
       background-color: transparent;
       border: none;
       border-bottom: 2px solid black;
   }

   .in:focus{
   outline: none;
   }
   
   
   


   @media (max-width: 767px) {
    .name{
        flex-direction: column;
        gap: 8px;
        align-items: start !important;
        
    }
    .pic-advertisement, .vid-advertisement{
        flex-wrap: wrap;
    }
    .categ-cont{
        display: flex;
        flex-direction: column;
        top: 0;
        margin: 0px;
        align-items: center;
        overflow: hidden;
    }
    .visa-photo, .plane-photo{
        width: 100%;
        position: relative !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
      
    }
    .first-advertisement-video, .second-advertisement-video{
        width: 100%;
    }
}











 /* form css  */

 .form{
    background-image: url(../../Assets/blue-bg.jpg);
    background-size: cover;
    mask-repeat: no-repeat;
    display: flex;
    gap: 5%;
    padding: 40px;
    border-radius: 20px;
    /* margin: 8% 20% 20% 20%; */
    margin: 15%;
    position: fixed;
    z-index: 2;
    height: 98vh;
    overflow: auto;
}

.form::-webkit-scrollbar{
    display: none;
}

.form-left{
    width: 60%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.form-image-container{
    width: 100%;
}

.form-plane, .text-form, .form-logo, .form-map{
    max-width: 100%;
   
}


.form-right{
    width: 100%;
}

.form-plane{
    position: relative;
    top: -60%;
    left: -9%;
}

.logo-form-container{
    margin-top: -70%;
}
.form-main-fields{
    background-color: white;
    border-radius: 18px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
   }

.passenger-input{
    gap: 20px;
}

.number-in{
    width: 60px;
    background-color: #D9D9D9;
   color:  #141C4E;
  aspect-ratio: 2/1;
  text-align: center;
  border: none;
   border-radius: 4px;
}

.number-in:focus{
    outline: none;
}

.plus, .minus{
    width: 24px;
    font-size: 20px;
    text-align: center;
    border-radius: 4px;
    font-weight: 600;
    background-color: #D9D9D9;
    cursor: pointer;
}


.small-width{
    max-width: 80%;
  }
.input-group{
    gap: 10px;
}

.submit-button{
    background-color: #96BFB1;
    border-radius: 6px;
    padding: 22px;
    max-width: fit-content;
    text-align: center;
    gap: 14px;
}
.in{
    font-size: 16px;
}
@media (max-width: 800px){
    
    .modal{
        overflow: auto;
    }
  .form-left{
    display: none;
  }
 

  .des{
    flex-direction: column;
    align-items: start !important;
  }
  .form{
    margin: 60px;
    height: auto;
     z-index: 10;
     padding: 10px;
     text-align: left !important;
     max-height: 100vh;
     overflow-y: auto;
  }

  .form-right{
    margin-top: 16px;
  }
 
 


  .des{
    text-align: left;
  }
  .form-main-fields{
    margin-top: 20px;
  }
}

@media (max-width: 1028px){
    .modal{
        display: flex;
        justify-content: center;
        align-items: center;
        
    }
    .form{
    /* top: 20%; */
    height: auto;
    }
    .form-left{
        display: none;
    }
    .cross-btn{
        top: 2%;
        color: white;
    }
   
}