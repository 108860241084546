.hero-container{
    background-image: url('../../Assets/main-background.jpg');
    background-size: cover;
    mask-repeat: no-repeat;
    height: 70vh;
    background-position: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 0 400px rgba(0, 0, 0, 1);
}



.hero-bottom{
    background-color: black;
    color: white;
    padding: 10px;
    justify-content: center;
    gap: 74px;
    flex-wrap: wrap;
}

.hero-head{
    color: white;
    margin: auto;
}

.hero-shorts{
    color: white;
    gap: 4px;
}

.shorts-span .shorts-icons{
    font-size: 16px;
}

.shorts-icons{
    color: green;
}

@media (max-width: 767px){
    .hero-bottom{
        gap: 20px;
    }
}
