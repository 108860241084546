.quickgateways{
    border-radius: 12px;
    background-color: #f6edee;
      padding: 5% 10% 1% 10%;
      margin-top: 10%;
      display: flex;
      flex-direction: column;
      gap: 0px;
  }
  
  .villas-button{
    padding: 20px 40px;
    border-radius: 12px;
  }
  .villa-buttons{
    width: 100%;
    margin: 40px auto;
    justify-content: center;
    align-items: center;
    gap: 40px;
  }
  .quick-upper{
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .first-card, .third-card{
      gap: 20px;
      flex-wrap: wrap;
    
  }
  
  .quick-short-span{
    font-size: 14px;
    font-weight: lighter;
  }
  .quick-card-outer{
    gap: 40px;
  }
  
  .firstimage, .rectimage{
      width: 370px;
      aspect-ratio: 370/190;
      object-fit: cover;
      border-radius: 12px;
  }
  .secondimage, .thirdimage{
      width: 175px;
      aspect-ratio: 175/190;
      object-fit: cover;
      border-radius: 12px;
  }
  
  .squareimage{
      width: 370px;
      aspect-ratio: 350/400;
      height: 400px;
      object-fit: cover;
      border-radius: 12px;
  }
  
  .quick-slider-wrapper {
      display: flex;
      gap: 50px;
      transition: transform 0.5s ease-in-out;
      width: 100%;
    }
  
    .quick-slider-main-wrap{
      overflow: auto;
    }
  
    .quick-slider-main-wrap::-webkit-scrollbar{
      display: none;
    }
    
    .quick-card-image-container{
      position: relative;
    }
  
    .quick-desc{
      position: absolute;
      bottom: 10px;
      left: 20px;
      color: white;
    }
  
    div.quick-desc h3{
      font-size: 22px;
    }
  
    div.quick-desc p{
      font-size: 13px;
    }
  
    @media (max-width: 767px){
     .firstimage, .rectimage, .squareimage{
      width: 330px;
     }
     .secondimage, .thirdimage{
      width: 150px;
     }
     .squareimage{
      height: 360px;
     }
     .villas-button{
      padding: 14px 20px;
     }
  }