.contact-map{
    width: 100%;
    height: 60vh;
    margin-top: 6%;
    background-size: cover;
    mask-repeat: no-repeat;
}

.contact-desc{
    margin-top: 20px;
    align-items: start !important;
    gap: 80px;
    flex-wrap: wrap;
}

.contact-container{
    padding: 2% 15%;
}

.talk{
    font-size: 38px;
    color: #08B367;
    font-weight: 700;
    margin-bottom: 10px;
}

.contact-desc-heading{
    font-weight: 700;
    font-size: 24px;
    text-transform: uppercase;
}
.contact-desc-column{
    display: flex;
    flex-direction: column !important;
    gap: 10px;
}

.cont-links{
    color: gray !important;
    font-size: 20px;
}