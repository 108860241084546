
.ls-navbar{
    padding: 0% 5%;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    top: 10px;
}

.company-logo{
    width: 40%;
    object-fit: contain;
    aspect-ratio: 3/2;
}

.ls-nav-links{
    gap: 24px;
    color: white;
}

.ls-link{
    color: white;
    font-weight: 600;
}

.navbar-mobile{
    display: none;
}

.mega-box{
   position: relative;
   cursor: pointer;
}

.mini-menu{
    display: none;
}

.active-links{
    width: 150px;
    cursor: pointer;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border: 1px solid black;
    border-radius: 12px;
    padding: 20px;
    top: 10;
}


@media only screen and (max-width: 767px){
.navbar-mobile{
    background-color: white;
    width: 100%;
    display: block;
    position: fixed;
    z-index: 4;
    bottom: 0;
    color: black;
}
.navbar-main-container{
    justify-content: space-between;
    padding: 30px;
}

.navbar-links{
    flex-direction: column;
    color: black;
    font-size: 20px;
}

.ls-nav-links{
    display: none;
}

.company-logo{
    width: 30%;
}
}