.testimonial-heading{
    margin-top: 5%;
    text-align: center;
    margin-bottom: 20px;
}
.testimonials-outer-container{
width: 100%;
 padding: 5% 10%;
 gap: 40px;
 align-items: start;
 position: relative;
 margin-bottom: 40px;
}

.square-div{
    
    position: absolute;
    width: 15%;
    height: 50%;
    background-color: #08B367;
    bottom: -60px;
}

.testimonial-person-image{
    width: 30%;
    height: 260px;
    object-fit: cover;
    position: absolute;
    left: 12%;
    top:20%;
}

.testimonial-person-image:hover{
    transform: rotate(-2deg);
}

.testimonial-rating-div{
    position: absolute;
    border-radius: 29px;
    padding: 4px 10px;
    background-color: blue;
    color: white;
    left: 40%;
    font-size: 18px;
}

.testimonial-rating-div:hover{
  transform: translateX(-20px);
}
.testimonial-image-container, .testimonial-content{
    width: 100%;
}


@media only screen and (max-width: 767px){
    .testimonials-outer-container{
        flex-direction: column-reverse;
        margin-bottom: 80px;
    }
    .testimonial-image-container{
        padding: 40px;
    }
    .square-div{
        height: 22%;
        width: 30%;
    }
    .testimonial-person-image{
        width: 50%;
        height: 132px;
        object-fit: cover;
        top: 75%;
        left: 25%;
    }
    .testimonial-rating-div{
        top: 15px;
        right: 20px;
        left: 80%;
    }
}