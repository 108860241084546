.vacation{
    padding: 5% 10%;
}

.vacation-inner-container{
    text-align: center;
    /* background-color: rgb(16, 16, 86); */
    background-image: url("../../Assets/blueback.png");
    background-size: cover;
    gap: 20px;
    justify-content: center; 
    border-radius: 60px;  
    padding: 40px 16px; 
    flex-direction: column;
    color: white;
}
.white-text{
    color: white;
}

.vacation-image-cards{
    gap: 24px;
    justify-content: center;
    flex-wrap: wrap;
}

.trav{
    color: red;
    font-size: 40px;
}

.vacation-desc-container{
    background-color: green;
    margin: -5px;
    padding: 8px;
    width: 100%;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}
.vacation-card{
    flex-direction: column;
}
.vacation-image{
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    width: 220px;
    aspect-ratio: auto 220/140;
}

@media (max-width: 767px) {
    .vacation{
        padding: 5%;
    }
    .vacation-image{
        width: 100%;
    }
    .vacation-image-cards{
        display: grid;
        grid-template-columns: auto auto;
    }
}