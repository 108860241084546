.card-outer{
    padding: 5% 10%;
}

.card-buttons-box{
    margin-top: 20px;
    gap: 20px;
}
.card{
    width: 100%;
    border: 1px solid gray;
    border-radius: 12px;
    gap: 20px;
    align-items: start !important;
    padding: 4px;
    margin-top: 40px;
}

.card-desc{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-image-container{
   flex: 1;
   height: 100%;
}

.card-image{
   flex: 1;
   min-height: 245px;
    width: 100%;
  height: auto;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.card-desc{
    text-align: left;
    flex: 2;
}

.card-desc-heading{
    font-size: 24px;
}

.card-desc-paras{
    gap: 20px;
}

.card-short-offers{
    background-color: rgb(240, 216, 220);
    border-radius: 18px;
    padding: 2px 10px;
    
}
.card-short-offers-container{
    gap: 10px;
    flex-wrap: wrap;
}

.card-price-desc-container{
background-color: rgb(241, 237, 237);
background-size: cover;
border-top-right-radius: 12px;
border-bottom-right-radius: 12px;
padding: 1.8rem;
flex-direction: column;
justify-content: center;
min-height: 250px;
flex: 1;
}

.old-price{
    text-decoration: line-through;
}

.sale-percentage{
    background-color: rgb(243, 32, 95);
    border-radius: 18px;
    padding: 2px 10px;
    display: inline;
}

.view-deals-button{
    background-color: #08B367;
    border-radius: 8px;
    padding: 20px 10px;
    display: inline;
    color: white;
}

.old-price-detail{
    gap: 10px;
}

.card-para-icon{
    margin: 4px;

}

@media (max-width: 767px){
    .card, .card-price-desc-container{
        flex-direction: column;
        align-items: center !important;
    }
    .card-image-container{
        width: 100%;
    }
  
}

@media (max-width: 900px){
    .card-outer{
        margin-top: 40px;
    }
    .card, .card-price-desc-container{
        flex-direction: column;
        align-items: center !important;
        min-height: auto;
        width: 100%;
    }
    .card-image-container{
        width: 100%;
    }
  
}