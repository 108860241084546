body{
  max-width: 1900px;
   margin: auto;
 }
 *{
   padding: 0;
   margin: 0;
   box-sizing: border-box;
   font-family: 'Manrope-regular', sans-serif;
 transition: .5s ease-in-out ;
 
 }
 
 a{
   text-decoration: none;
 }
 
 .flex{
   display: flex;
   align-items: center;
 }
 
 h1{
   font-size: 40px;
 }
 
 h2{
   font-size: 20px;
 }
 
 
 .pointer{
   cursor: pointer;
 }
 
 .curve{
   border-radius: 12px;
 }
 @media (max-width: 767px){
   h1{
      font-size: 22px;
      font-weight: 700;
   }
   h2{
     font-size: 18px;
     font-weight: 600;
   }
 }
 
 
 .category-label {
   display: flex;
   align-items: center;
 }
 
 .category-label label {
   position: relative;
   cursor: pointer;
   margin-right: 20px;
 }
 
 .category-label input[type="checkbox"] {
   display: none;
 }
 
 .category-label span {
   position: relative;
   display: inline-block;
   padding-left: 25px; /* Adjust based on checkbox size */
 }
 
 .category-label span::before {
   content: '';
   position: absolute;
   top: 2px;
   left: 0;
   width: 10px; /* Adjust based on checkbox size */
   height: 10px; /* Adjust based on checkbox size */
   border: 2px solid #000; /* Border color */
   border-radius: 50%;
   padding: 2px;
 }
 
 .category-label input[type="checkbox"]:checked + span::before {
   background-color: #000; /* Fill color when checkbox is checked */
   border: 2px solid black;
 }