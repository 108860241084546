.community-image{
    width: 100%;
    height: 80vh;
}
.about-outer-container{
    margin-top: 20px;
    text-align: center;
}

.about-inner-containers{
    padding: 2% 10%;
    gap: 10%;
    text-align: left;
    
}

.about-column, .column-para{
    width: 50%;
}

.reflect-photo{
    height: 200px;
    -webkit-box-reflect: below 0px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
}

.about-outer-container :nth-child(4){
    flex-direction: row-reverse;
}

.about-main-heading{
    margin-top: 40px;
}

@media (max-width: 780px){
    .about-column, .column-para{
        width: 100%;
    }
    .community-image{
        height: auto;
    }
    .reflect-photo{
        -webkit-box-reflect: left 4px linear-gradient(to left, rgba(0,0,0,0.0), rgba(0,0,0,0.4));
    }
    .about-inner-containers{
        flex-direction: column;
    }
    .about-outer-container :nth-child(4){
        flex-direction: column;
    }
}